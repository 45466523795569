import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { WalletOutlined, CloseOutlined } from "@ant-design/icons";
import {
  IExchange,
  bscDexList,
  cexList,
  ftmDexList,
  othersDexList,
} from "../constants/exchange";
import { ExchangeSavingTooltip } from "./ExchangeSavingTooltip";
import "./BuyAlpacaModal.css";

export function BuyAlpacaModal({
  visible,
  onCancel,
}: {
  visible: boolean;
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Transition show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onCancel}>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="dialog-container">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="c-panel">
                <Dialog.Title>
                  <div className="c-title">
                    <div className="flex flex-row items-center">
                      <WalletOutlined className="text-primary w-8 mr-4" />
                      <span>{t("Buy ALPACA")}</span>
                      <CloseOutlined
                        className="ml-auto text-base"
                        onClick={onCancel}
                      />
                    </div>
                  </div>
                </Dialog.Title>
                <DialogContent />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function DialogContent() {
  return (
    <div className="c-content">
      <DialogRow
        title={"Decentralized Exchanges"}
        exchanges={[...bscDexList, ...ftmDexList, ...othersDexList]}
      />
      <div className="border mt-4" />
      <DialogRow title="Centralized Exchanges" exchanges={cexList} />
    </div>
  );
}

function DialogRow({
  title,
  exchanges,
}: {
  title: string;
  exchanges: IExchange[];
}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col mt-6">
      <div className="c-subtitle">{title}</div>
      <div className="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 gap-4 mt-2">
        {exchanges.map((exchange, index) => {
          const {
            name,
            type,
            iconUrl,
            displayName,
            tradeUrl,
            optionalUrl,
            isNew,
          } = exchange;
          return (
            <div key={name} className="c-exchange">
              {isNew && (
                <span className="c-tag c-tag--new px-1.5">{t("New")}</span>
              )}
              <a
                href={tradeUrl}
                className="c-logo"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <img
                  className={`${name.toLowerCase()}-${type.toLowerCase()} c-image`}
                  src={iconUrl}
                />
                {displayName.map((name, index) => {
                  return <p key={`displayname-${name}-${index}`}>{name}</p>;
                })}
              </a>
              {optionalUrl && <ExchangeSavingTooltip exchange={exchange} />}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export const BIG_PAIRS = [
  "CAKE-BNB",
  "BTCB-BNB",
  "DOT-BNB",
  "BNB-USDT",
  "LINK-BNB",
  "UNI-BNB",
  "CAKE-USDT",
  "ALPACA-BUSD",
  "AXS-BNB",
  "CAKE-BUSD",
  "BTCB-BUSD",
  "BTCB-USDT",
  "USDC-USDT",
  "BUSD-USDT",
];

export enum PagesType {
  DEFAULT,
  LANDINGPAGE,
  INSTITUTIONS,
}

export const pageTitles: Record<PagesType, string> = {
  [PagesType.DEFAULT]: "Alpaca Finance",
  [PagesType.LANDINGPAGE]:
    "Alpaca Finance - Largest platform of leveraged DeFi products",
  [PagesType.INSTITUTIONS]:
    "Are you an institution looking to earn yields with DeFi? - Alpaca Finance",
};

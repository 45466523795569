import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import alpacaLogo from "~/images/alpaca-logo.svg";

import LanguageDropdown from "./LanguageDropdown";
import MobileMenu from "./MobileMenu";
import { getMenuItems } from "./utils";
import { BuyAlpacaModal } from "~/ui/BuyAlpacaModal";
import Button from "~/ui/Button";
import './Header.css';

const SCROLL_THRESHOLD = 120;

const Header: React.FC = () => {
  const { t } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [buyAlpacaModal, setBuyAlpacaModal] = useState(false);

  const onClick = () => {
    setBuyAlpacaModal(true)
  }
  const onCancel = () => {
    setBuyAlpacaModal(false)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= SCROLL_THRESHOLD) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={cx(
        "fixed z-50 w-full lg:relative lg:w-auto headerMenu",
        isScrolled && "bg-dark-500 shadow-sm"
      )}
    >
      <header className="container mx-auto flex justify-between items-center py-4 transition-colors duration-300">
        <div className="flex items-center">
          <Link to="/" className="relative z-50 flex-none">
            <img src={alpacaLogo} className="lg:w-16" alt="Alpaca Logo" />
          </Link>
          <div className="hidden lg:flex ml-12">
            {getMenuItems(t).map(({ label, to, target, rel }) => (
              <Link className="px-3" to={to} key={to} target={target} rel={rel}>
                {label}
              </Link>
            ))}
          </div>
        </div>
        <div className="flex items-center lg:gap-x-3">
          {/* <div className="relative z-50">
            <LanguageDropdown />
          </div> */}
          <div className="hidden lg:block">
            <Button className="hidden lg:block text-lg" onClick={onClick}>
              {t("Buy ALPACA")}
            </Button>
            <BuyAlpacaModal visible={buyAlpacaModal} onCancel={onCancel}/>
          </div>
          <a href="https://app.alpacafinance.org">
            <Button className="hidden lg:block text-lg" appearance="fill">
              {t("Launch AF1")}
            </Button>
          </a>
          <a href="https://app-v2.alpacafinance.org/">
            <Button className="hidden lg:block text-lg highlight" appearance="fill">
              <div className="relative"></div>
              {t("Launch AF2")}
            </Button>
          </a>
          <MobileMenu />
        </div>
      </header>
    </div>
  );
};

export default Header;

import React from 'react'
import { Tooltip } from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import { IExchange } from '../constants/exchange'

import './ExchangeSavingTooltip.css'
import 'react-tooltip/dist/react-tooltip.css'

export function ExchangeSavingTooltip({ exchange }: { exchange: IExchange }): JSX.Element {
  const { t } = useTranslation()
  const tooltipContent: string = t("Click here to create a new {{exchangeName}} account with our Referral ID. You will save {{percent}}% on your trading fee.", {
    exchangeName: exchange.name,
    percent: exchange.savingPercent,
  })
  
  return (
    <div>
      <p id="exchange-saving" data-tooltip-content={tooltipContent} data-tooltip-place="top">
        <a target="_blank" href={exchange.optionalUrl}>
          <button className="c-button">
            <p className="c-button__text">{t("Sign Up Here")}</p>
            <p className="c-button__text">
              {t("Save {{percent}}%", { percent: exchange.savingPercent })}
            </p>
          </button>
        </a>
      </p>
      <Tooltip anchorId="exchange-saving" />
    </div>
  )
}

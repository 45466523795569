import React from "react";
import cx from "classnames";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import alpacaLogo from "~/images/alpaca-logo.svg";
import trademarkIcon from "~/images/icons/trademark.svg";
import twitterIcon from "~/images/social-icons/twitter.svg";
import telegramIcon from "~/images/social-icons/telegram.svg";
import discordIcon from "~/images/social-icons/discord.svg";
import mediumIcon from "~/images/social-icons/medium.svg";
import youtubeIcon from "~/images/social-icons/youtube.svg";
import redditIcon from "~/images/social-icons/reddit.svg";
import githubIcon from "~/images/social-icons/github.svg";
import Button from "~/ui/Button";
import { getLinkGroups } from "./utils";

const socialLinks = [
  {
    icon: twitterIcon,
    alt: "twitter",
    href: "https://twitter.com/AlpacaFinance",
  },
  { icon: telegramIcon, alt: "telegram", href: "https://t.me/alpacafinance" },
  {
    icon: discordIcon,
    alt: "discord",
    href: "https://discord.com/invite/jkC2j9ZVDf",
  },
  {
    icon: mediumIcon,
    alt: "medium",
    href: "https://medium.com/alpaca-finance",
  },
  {
    icon: youtubeIcon,
    alt: "youtube",
    href: "https://www.youtube.com/channel/UC8xBPBPgRD-xe_ZfyOwV_Dg",
  },
  {
    icon: redditIcon,
    alt: "reddit",
    href: "https://www.reddit.com/r/AlpacaFinanceOfficial/",
  },
  {
    icon: githubIcon,
    alt: "github",
    href: "https://github.com/alpaca-finance/bsc-alpaca-contract",
  },
];

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="py-16 bg-dark-500">
      <div className="container mx-auto">
        <div className="lg:flex lg:items-start">
          <div className="lg:mr-44">
            <Link
              to="/"
              className="text-primary font-bold flex items-center lg:block"
            >
              <img
                src={alpacaLogo}
                alt="Alpaca Logo"
                className="mr-2 lg:w-14"
                style={{ display: "inline" }}
              />
              <span className="lg:mt-8 text-xl">{t("Alpaca Finance")}</span>
            </Link>

            <p className="hidden lg:block mt-5 text-xs w-32">
              {t("The leveraged yield farming protocol")}
            </p>
          </div>
          <div className="flex flex-col flex-wrap gap-y-6 mt-8 max-h-72 lg:flex-row lg:flex-nowrap lg:flex-1 lg:mt-0">
            {getLinkGroups(t).map(({ title, items, className }) => (
              <div
                key={title}
                className={cx(
                  "flex flex-col gap-y-6 w-1/3 md:w-1/3 lg:flex-1",
                  className
                )}
              >
                <div className="font-bold">{title}</div>
                {items.map(({ label, to, target, rel }) => (
                  <div key={to}>
                    <Link
                      to={to}
                      target={target}
                      rel={rel}
                      className="text-xs text-light"
                    >
                      {label}
                    </Link>
                  </div>
                ))}
              </div>
            ))}
            <div className="lg:flex flex-col gap-3 order-last hidden">
              <a href="#">
                <Link to="https://app.alpacafinance.org">
                  <Button className="px-3 py-1 w-full">
                    <div className="text-lg">{t("Launch AF1")}</div>
                  </Button>
                </Link>
              </a>
              <a href="#">
                <Link to="https://app-v2.alpacafinance.org/">
                  <Button className="py-1 w-full">
                    <div className="text-lg">{t("Launch AF2")}</div>
                  </Button>
                </Link>
              </a>
            </div>
          </div>
        </div>
        <div className="lg:flex lg:flex-row-reverse lg:justify-between lg:items-center lg:mt-28">
          <div className="mt-20 flex justify-between items-center lg:mt-0 lg:gap-x-6">
            {socialLinks.map(({ icon, alt, href }) => (
              <a
                href={href}
                key={alt}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <img src={icon} alt={alt} className="w-5" />
              </a>
            ))}
          </div>
          <div className="mt-8 flex items-center lg:mt-0">
            <img src={trademarkIcon} alt="trademark" />
            <span className="text-light ml-2 text-xs">
              {new Date().getFullYear()} Alpaca Fin Corporation
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;

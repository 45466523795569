import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "gatsby-plugin-react-i18next";
import React from "react";
import { PagesType, pageTitles } from "~/constants";
import Footer from "./Footer";
import Header from "./Header";
import "./LandingLayout.css";
import { getSEOMetaItemsByPageType } from "./utils";

interface Props {
  title?: string;
  pageType?: PagesType;
}

const LandingLayout: React.FC<Props> = ({ title, pageType, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div>
      <Helmet meta={getSEOMetaItemsByPageType(pageType)}>
        {!pageType ? (
          <title>{data.site.siteMetadata.title}</title>
        ) : (
          <title>{pageTitles[pageType]}</title>
        )}
        <script
          src="https://tag.safary.club/stag-0.1.6.js"
          data-name="safary-sdk"
          data-product-id="prd_BD6D9MBnHD"
          integrity="sha256-YzV6CVGFURoZE0BRupkJJaRrlcKfjPa7KVN0/Ds9X8k="
          crossOrigin="anonymous"
          async
        ></script>
      </Helmet>
      <Header />

      <div className="pt-16 lg:pt-0">{children}</div>
      <Footer />
    </div>
  );
};

export default LandingLayout;

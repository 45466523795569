import { PagesType } from "./../constants/index";
import { TFunction } from "i18next";
import landingImagePath from "~/images/seo/alpaca-finance-leverage-yield-farming-defi-crypto.jpeg";
import institutionsImagePath from "~/images/seo/alpaca_finance_institutions_defi_crypto.jpeg";

export function getLinkGroups(t: TFunction) {
  return [
    {
      title: t("Products"),
      items: [
        {
          label: t("Lend"),
          to: "https://app.alpacafinance.org/lend",
          target: "_self",
        },
        {
          label: t("Farm"),
          to: "https://app.alpacafinance.org/farm",
          target: "_self",
        },
        {
          label: t("Alpies"),
          to: "https://alpies.alpacafinance.org",
          target: "_blank",
          rel: "nofollow noopener noreferrer",
        },
      ],
    },
    {
      title: t("Contact"),
      className: "md:order-3",
      items: [
        {
          label: t("Careers"),
          to: "https://forms.gle/4cRbgf5kRa4q4c777",
          target: "_blank",
          rel: "nofollow noopener noreferrer",
        },
        { label: t("Institutions"), to: "/institutions", target: "_self" },
        {
          label: t("Partnerships"),
          to: "https://forms.gle/DNf23tLXTbaDSuLbA",
          target: "_blank",
          rel: "nofollow noopener noreferrer",
        },
      ],
    },
    {
      title: t("Support"),
      items: [
        {
          label: t("FAQ"),
          to: "https://docs.alpacafinance.org/faqs",
          target: "_blank",
          rel: "nofollow noopener noreferrer",
        },
        {
          label: t("Step-by-Step Guides"),
          to: "https://docs.alpacafinance.org/our-protocol-1/step-by-step-guide",
          target: "_blank",
          rel: "nofollow noopener noreferrer",
        },
        {
          label: t("Docs"),
          to: "https://docs.alpacafinance.org",
          target: "_blank",
          rel: "nofollow noopener noreferrer",
        },
        {
          label: t("Video Tutorials"),
          to: "https://www.youtube.com/channel/UC8xBPBPgRD-xe_ZfyOwV_Dg",
          target: "_blank",
          rel: "nofollow noopener noreferrer",
        },
      ],
    },
  ];
}

export function getMenuItems(t: TFunction) {
  return [
    {
      label: t("FAQ"),
      to: "https://docs.alpacafinance.org/faqs",
      target: "_blank",
      rel: "nofollow noopener noreferrer",
    },
    { label: t("Security"), to: "/#security" },
    { label: t("Institutions"), to: "/institutions", target: "_self" },
    {
      label: t("Developers"),
      to: "https://docs.alpacafinance.org/integrating-with-alpaca-finance",
      target: "_blank",
      rel: "nofollow noopener noreferrer",
    },
    {
      label: t("Docs"),
      to: "https://docs.alpacafinance.org",
      target: "_blank",
      rel: "nofollow noopener noreferrer",
    },
    {
      label: t("Alpies"),
      to: "https://alpies.alpacafinance.org",
      target: "_blank",
      rel: "nofollow noopener noreferrer",
    },
  ];
}

const SEOMetaItemsLandingPage = [
  // <!-- Search Engine --> */
  {
    name: "description",
    content:
      "Alpaca Finance is the largest platform of leveraged DeFi products, offering up to 6x leverage on BNB Chain and Fantom.",
  },
  {
    name: "image",
    content: "https://www.alpacafinance.org" + landingImagePath,
  },
  {
    name: "robots",
    content: "index, follow",
  },
  // <!-- Schema.org for Google --> */
  {
    itemProp: "name",
    content: "Alpaca Finance - Largest platform of leveraged DeFi products",
  },
  {
    itemProp: "description",
    content:
      "Alpaca Finance is the largest platform of leveraged DeFi products, offering up to 6x leverage on BNB Chain and Fantom.",
  },
  {
    itemProp: "image",
    content: "https://www.alpacafinance.org" + landingImagePath,
  },
  // <!-- Twitter --> */
  {
    name: "twitter:card",
    content: "summary",
  },
  {
    name: "twitter:title",
    content: "Alpaca Finance - Largest platform of leveraged DeFi products",
  },
  {
    name: "twitter:description",
    content:
      "Alpaca Finance is the largest platform of leveraged DeFi products, offering up to 6x leverage on BNB Chain and Fantom.",
  },
  {
    name: "twitter:site",
    content: "@alpacafinance",
  },
  {
    name: "twitter:creator",
    content: "@alpacafinance",
  },
  {
    name: "twitter:image:src",
    content: "https://www.alpacafinance.org" + landingImagePath,
  },
  /* <!-- Open Graph general (Facebook, Pinterest & Google+) --> */
  {
    name: "og:title",
    content: "Alpaca Finance - Largest platform of leveraged DeFi products",
  },
  {
    name: "og:description",
    content:
      "Alpaca Finance is the largest platform of leveraged DeFi products, offering up to 6x leverage on BNB Chain and Fantom.",
  },
  {
    name: "og:image",
    content: "https://www.alpacafinance.org" + landingImagePath,
  },
  {
    name: "og:url",
    content: "https://www.alpacafinance.org",
  },
  {
    name: "og:site_name",
    content: "Alpaca Finance",
  },
  {
    name: "og:type",
    content: "website",
  },
];

const SEOMetaItemsInstitutionsPage = [
  // <!-- Search Engine --> */
  {
    name: "description",
    content:
      "Our DeFi Offerings: • Lending • Up to 6x Leverage • Market Neutral Strategies • Short Strategies • Structured Products • Automated Earn Vaults",
  },
  {
    name: "image",
    content: "https://www.alpacafinance.org" + institutionsImagePath,
  },
  {
    name: "robots",
    content: "index, follow",
  },
  // <!-- Schema.org for Google -->
  {
    itemprop: "name",
    content:
      "Are you an institution looking to earn yields with DeFi? - Alpaca Finance",
  },
  {
    itemprop: "description",
    content:
      "Our DeFi Offerings: • Lending • Up to 6x Leverage • Market Neutral Strategies • Short Strategies • Structured Products • Automated Earn Vaults",
  },
  {
    itemprop: "image",
    content: "https://www.alpacafinance.org" + institutionsImagePath,
  },
  // <!-- Twitter -->
  {
    name: "twitter:card",
    content: "summary",
  },
  {
    name: "twitter:title",
    content:
      "Are you an institution looking to earn yields with DeFi? - Alpaca Finance",
  },
  {
    name: "twitter:description",
    content:
      "Our DeFi Offerings: • Lending • Up to 6x Leverage • Market Neutral Strategies • Short Strategies • Structured Products • Automated Earn Vaults",
  },
  {
    name: "twitter:site",
    content: "@alpacafinance",
  },
  {
    name: "twitter:creator",
    content: "@alpacafinance",
  },
  {
    name: "twitter:image:src",
    content: "https://www.alpacafinance.org" + institutionsImagePath,
  },
  // <!-- Open Graph general (Facebook, Pinterest & Google+) -->
  {
    name: "og:title",
    content:
      "Are you an institution looking to earn yields with DeFi? - Alpaca Finance",
  },
  {
    name: "og:description",
    content:
      "Our DeFi Offerings: • Lending • Up to 6x Leverage • Market Neutral Strategies • Short Strategies • Structured Products • Automated Earn Vaults",
  },
  {
    name: "og:image",
    content: "https://www.alpacafinance.org" + institutionsImagePath,
  },
  {
    name: "og:url",
    content: "https://www.alpacafinance.org/institutions/",
  },
  {
    name: "og:site_name",
    content: "Alpaca Finance",
  },
  {
    name: "og:type",
    content: "website",
  },
];

export function getSEOMetaItemsByPageType(pageType: PagesType) {
  if (pageType == PagesType.INSTITUTIONS) {
    return SEOMetaItemsInstitutionsPage;
  }
  return SEOMetaItemsLandingPage;
}

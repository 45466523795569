export enum Exchanges {
  DEX = "DEX",
  CEX = "CEX",
}

export interface IExchange {
  name: string;
  displayName: string[];
  type: Exchanges;
  iconUrl: string;
  tradeUrl: string;
  optionalUrl?: string;
  savingPercent?: number;
  isNew?: boolean;
}

export const bscDexList: IExchange[] = [
  {
    name: "Pancakeswap",
    displayName: ["Pancake", "Swap"],
    type: Exchanges.DEX,
    iconUrl:
      "https://alpaca-app-assets.alpacafinance.org/icons/iconswap/pancakeswap.svg?v=2",
    tradeUrl:
      "https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F",
  },
  {
    name: "Biswap",
    displayName: ["Biswap"],
    type: Exchanges.DEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/coins/bsw.svg",
    tradeUrl:
      "https://biswap.org/?utm_source=Alpaca&utm_medium=referral&utm_campaign=button_collab_BD",
    isNew: false,
  },
  {
    name: "MDEX",
    displayName: ["MDEX"],
    type: Exchanges.DEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/coins/mdex.svg",
    tradeUrl:
      "https://bsc.mdex.com/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F",
  },
];

export const ftmDexList: IExchange[] = [
  {
    name: "Spookyswap",
    displayName: ["Spooky", "Swap"],
    type: Exchanges.DEX,
    iconUrl:
      "https://alpaca-app-assets.alpacafinance.org/icons/iconswap/spookyswap.svg",
    tradeUrl:
      "https://spookyswap.finance/swap?inputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75&outputCurrency=0xad996a45fd2373ed0b10efa4a8ecb9de445a4302",
  },
];

export const othersDexList: IExchange[] = [
  {
    name: "OpenOcean",
    displayName: ["Open", "Ocean"],
    type: Exchanges.DEX,
    iconUrl:
      "https://alpaca-app-assets.alpacafinance.org/icons/iconswap/openocean.svg",
    tradeUrl: "https://app.openocean.finance/CLASSIC#/BSC/BUSD/ALPACA",
  },
];

export const cexList: IExchange[] = [
  {
    name: "Binance",
    displayName: ["Binance"],
    type: Exchanges.CEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/binance.svg",
    tradeUrl: "https://www.binance.com/en/trade/ALPACA_USDT",
  },
  {
    name: "Gate.io",
    displayName: ["Gate.io"],
    type: Exchanges.CEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/gateio.svg",
    tradeUrl: "https://www.gate.io/en/trade/ALPACA_USDT",
  },
  {
    name: "Poloniex",
    displayName: ["Poloniex"],
    type: Exchanges.CEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/poloniex.svg",
    tradeUrl: "https://poloniex.com/trade/ALPACA_USDT?type=spot",
  },
  {
    name: "MEXC",
    displayName: ["MEXC"],
    type: Exchanges.CEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/mexc.svg",
    tradeUrl: "https://www.mexc.com/exchange/ALPACA_USDT",
  },
  {
    name: "Indodax",
    displayName: ["Indodax"],
    type: Exchanges.CEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/indodax.svg",
    tradeUrl: "https://indodax.com/market/ALPACAIDR",
  },
  {
    name: "Tokocrypto",
    displayName: ["Tokocrypto"],
    type: Exchanges.CEX,
    iconUrl:
      "https://alpaca-app-assets.alpacafinance.org/icons/tokyocrypto.svg",
    tradeUrl: "https://www.tokocrypto.com/en/trade/ALPACA_USDT",
  },
  {
    name: "CoinDCX",
    displayName: ["CoinDCX"],
    type: Exchanges.CEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/coindcx.svg",
    tradeUrl: "https://coindcx.com/trade/ALPACAUSDT",
  },
  {
    name: "WazirX",
    displayName: ["WazirX"],
    type: Exchanges.CEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/wazirx.svg",
    tradeUrl: "https://wazirx.com/exchange/ALPACA-INR",
  },
  {
    name: "Latoken",
    displayName: ["Latoken"],
    type: Exchanges.CEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/latoken.svg",
    tradeUrl: "https://latoken.com/exchange/ALPACA_USDT",
  },
  {
    name: "WhiteBIT",
    displayName: ["WhiteBIT"],
    type: Exchanges.CEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/whitebit.svg",
    tradeUrl: "https://whitebit.com/trade/ALPACA-USDT",
  },
  {
    name: "DigiFinex",
    displayName: ["DigiFinex"],
    type: Exchanges.CEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/digifinex.svg",
    tradeUrl: "https://www.digifinex.com/en-ww/trade/USDT/ALPACA",
  },
  {
    name: "LBank",
    displayName: ["LBank"],
    type: Exchanges.CEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/lbank.svg",
    tradeUrl: "https://www.lbank.info/exchange/alpaca/usdt",
  },
  {
    name: "Bitrue",
    displayName: ["Bitrue"],
    type: Exchanges.CEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/bitrue.svg",
    tradeUrl: "https://www.bitrue.com/trade/alpaca_usdt",
  },
  {
    name: "CoinEx",
    displayName: ["CoinEx"],
    type: Exchanges.CEX,
    iconUrl: "https://alpaca-app-assets.alpacafinance.org/icons/coinex.svg",
    tradeUrl:
      "https://www.coinex.com/exchange?currency=usdt&dest=alpaca&tab=limit",
  },
];

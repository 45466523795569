import React, { useState } from "react";
import cx from "classnames";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import hamburgerIcon from "~/images/icons/hamburger.svg";
import Button from "~/ui/Button";
import { BuyAlpacaModal } from "~/ui/BuyAlpacaModal";
import { getMenuItems } from "./utils";

const MobileMenu: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [buyAlpacaModal, setBuyAlpacaModal] = useState(false);

  const onClick = () => {
    setBuyAlpacaModal(true);
  };
  const onCancel = () => {
    setBuyAlpacaModal(false);
  };

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className="lg:hidden">
      <button type="button" className="ml-5 relative z-50" onClick={toggle}>
        <img src={hamburgerIcon} alt="hamburger" />
      </button>
      <div
        className={cx(
          "fixed z-40 pt-16 bg-black inset-0 transform-gpu transition-transform duration-300",
          open ? "translate-x-0" : "translate-x-full"
        )}
      >
        <div className="container mx-auto text-right">
          {getMenuItems(t).map(({ label, to, target, rel }) => (
            <Link
              to={to}
              className="block w-full py-2 mb-2 "
              key={to}
              target={target}
              rel={rel}
            >
              {label}
            </Link>
          ))}
          <div className='flex flex-col gap-3'>
            <div className="block">
              <Button onClick={onClick}>{t("Buy ALPACA")}</Button>
              <BuyAlpacaModal visible={buyAlpacaModal} onCancel={onCancel} />
            </div>
            <a href="https://app.alpacafinance.org" className="block">
              <Button className="tracking-wider w-[8.2rem]" appearance="fill">{t("Launch AF1")}</Button>
            </a>
            <a href="https://app-v2.alpacafinance.org/" className="block">
              <Button className="tracking-wider w-[8.2rem] highlight" appearance="fill">{t("Launch AF2")}</Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
